import { instance } from '@/utils/http.js'

// 获取用户信息
export const getUserInfoApi = function () {
  return instance({
    url: '/api/v1/user/get_user',
    method: 'get',
  })
}

// 修改用户信息
export const editUserInfo = function (data) {
  return instance({
    url: '/api/v1/user/edit_user',
    method: 'post',
    data
  })
}

// 获取学生科目列表
export const getStuSubjectList = function () {
  return instance({
    url: '/api/v1/public/subjects',
    method: 'get',
  })
}

// 获取科目年级
export const getSubjectGrade = () => {
  return instance({
    url: '/api/v1/public/subject_grade',
    method: 'get',
  })
}

// 获取学生未分配的科目
export const getUndefinedSubject = () => {
  return instance({
    url: '/api/v1/user/allot_subject',
    method: 'get',
  })
}

// 获取学生科目模式
export const getSubjectMode = () => {
  return instance({
    url: '/api/v1/public/subjects_mode',
    method: 'get',
  })
}

// 修改学生科目模式
export const editStuSubject = (data) => {
  return instance({
    url: '/api/v1/user/edit_subject',
    method: 'post',
    data
  })
}

// 获取所有科目
export const getAllSubject = () => {
  return instance({
    url: '/api/v1/public/grades',
    method: 'get',
  })
}

// 批量修改学生科目年级
export const batchResetUserGradeSubject = (data) => {
  return instance({
    url: '/api/v1/user/reset_user',
    method: 'post',
    data
  })
}

// 修改密码
export const editPwd = (data) => {
  return instance({
    url: '/api/v1/user/edit_pwd',
    method: 'post',
    data
  })
}

// 退出登录
export const logOut = () => {
  return instance({
    url: '/api/v1/user/logout',
    method: 'get',
  })
}

// 反馈列表
export const getFeedbackList = () => {
  return instance({
    url: '/api/v1/error/lst',
    method: 'get',
  })
}

// 反馈详情
export const getFeedbackInfo = (params) => {
  return instance({
    url: '/api/v1/error/detail',
    method: 'get',
    params
  })
}

// 得分率情况
export const getScoreDetail = function () {
  return instance({
    url: '/api/v1/user/score_detail',
    method: 'get'
  })
}

// 成长值
export const getGrowData = function () {
  return instance({
    url: '/api/v1/user/max_score_detail',
    method: 'get'
  })
}

