<template>
  <div class="my_info_warp">
    <div class="head">
      修改密码
    </div>
    <a-form :model="form"
            ref="formRef"
            class="form_Warp"
            label-width="90px">
      <a-row>
        <a-col :span="24">

          <a-form-item label="输入旧密码:">
            <a-input v-model:value="form.old_password"
                     type="password"
                     style="width:100%"
                     placeholder="请输入旧密码" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">

          <a-form-item label="输入新密码:">
            <a-input v-model:value="form.password"
                     type="password"
                     style="width:100%"
                     placeholder="请输入新密码" />
          </a-form-item>
        </a-col>

      </a-row>
      <a-row>
        <a-col :span="24">

          <a-form-item label="再输新密码:">
            <a-input v-model:value="form.password1"
                     type="password"
                     style="width:100%"
                     placeholder="请再次输入新密码" />
          </a-form-item>
        </a-col>

      </a-row>
    </a-form>
    <a-row>
      <a-button @click="save"
                class="btn"
                type="primary">
        确 定
      </a-button>
    </a-row>
  </div>
</template>

<script setup>
import { reactive } from "@vue/reactivity";
import { message } from "ant-design-vue";
import { editPwd, logOut } from '@/api/user.js'
import { useRouter } from "vue-router";
const router = useRouter()

let form = reactive({
  old_password: '',
  password: '',
  password1: '',
})
/**
 * 修改密码之后，退出登录
 */
const save = async () => {
  let pForm = JSON.parse(JSON.stringify(form))
  if (pForm.old_password == '') {
    message.warning('请输入旧密码')
    return
  }
  if (pForm.password == '') {
    message.warning('请输入新密码')
    return
  }
  console.log('pForm', pForm)
  if (pForm.password != pForm.password1) {
    message.warning('两次密码输入不一致')
    return
  }
  delete pForm.password1
  await editPwd(pForm)
  message.success("修改成功")
  await logOut()
  message.success('退出登录成功')
  router.push('/login?not_auto_login=1')
}

</script>

<style lang="scss" scoped>
.my_info_warp {
  width: 642rem;
  margin: 0 auto;
  .head {
    margin-top: 60rem;
    font-size: 28rem;
    text-align: center;
  }
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60rem;
  margin-right: 0;
  font-size: 24rem;
  margin-top: 60rem;
}
:deep .ant-input {
  height: 60rem;
  font-size: 24rem;
}
:deep .ant-form-item-label > label {
  font-size: 24rem;
  height: 60rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #333333;
}
:deep .ant-form-item {
  margin: 30rem auto 0;
}
</style>